var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Tab as BaseTab } from '@headlessui/react';
import styled from 'styled-components';
import { color } from '../system';
export function Tab(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (React.createElement(BaseTab, __assign({}, rest, { className: function (_a) {
            var selected = _a.selected;
            return (selected ? 'selected' : '');
        } }), children));
}
var StyledTabList = styled(BaseTab.List)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: flex-end;\n\n  button {\n    display: flex;\n    flex: 1 1 auto;\n    padding: 12px 32px 12px 32px;\n    border: none;\n    background: transparent;\n    outline: none;\n    min-width: 250px;\n    font-size: 18px;\n    color: ", ";\n    border-bottom: 4px solid ", ";\n    animation-duration: 200;\n    animation-timing-function: ease-in-out;\n    transition: color;\n  }\n  .border-liner {\n    width: 100%;\n    display: flex;\n    padding: 8px 32px 8px 32px;\n    border-bottom: 4px solid ", ";\n    border-bottom-right-radius: 4px;\n  }\n  .selected {\n    color: ", ";\n    font-weight: 600;\n    border-bottom: 4px solid ", ";\n  }\n"], ["\n  display: flex;\n  align-items: flex-end;\n\n  button {\n    display: flex;\n    flex: 1 1 auto;\n    padding: 12px 32px 12px 32px;\n    border: none;\n    background: transparent;\n    outline: none;\n    min-width: 250px;\n    font-size: 18px;\n    color: ", ";\n    border-bottom: 4px solid ", ";\n    animation-duration: 200;\n    animation-timing-function: ease-in-out;\n    transition: color;\n  }\n  .border-liner {\n    width: 100%;\n    display: flex;\n    padding: 8px 32px 8px 32px;\n    border-bottom: 4px solid ", ";\n    border-bottom-right-radius: 4px;\n  }\n  .selected {\n    color: ", ";\n    font-weight: 600;\n    border-bottom: 4px solid ", ";\n  }\n"])), color.text.secondary, color.borders.decorative, color.borders.decorative, color.text.default, color.brand.secondary);
export function TabList(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (React.createElement(StyledTabList, __assign({}, rest),
        children,
        React.createElement("div", { className: "border-liner" }, "\u00A0")));
}
var StyledTabGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  > * + * {\n    margin-top: 1rem;\n  }\n"], ["\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  > * + * {\n    margin-top: 1rem;\n  }\n"])));
export function TabGroup(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return (React.createElement(BaseTab.Group, __assign({}, rest),
        React.createElement(StyledTabGroup, null, children)));
}
var StyledTabPanels = styled(BaseTab.Panels)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  display: block;\n"], ["\n  width: 100%;\n  display: block;\n"])));
export function TabPanels(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement(StyledTabPanels, __assign({}, rest), children);
}
var StyledTabPanel = styled(BaseTab.Panel)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  display: block;\n  outline: none;\n"], ["\n  width: 100%;\n  display: block;\n  outline: none;\n"])));
export function TabPanel(_a) {
    var children = _a.children, rest = __rest(_a, ["children"]);
    return React.createElement(StyledTabPanel, __assign({}, rest), children);
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
