"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Uploader = exports.UploadPicturesWrapper = exports.UploadPictureContainer = exports.RemoveHeading = exports.PreviewContainer = exports.FileUploadLabel = exports.ErrorsContainer = exports.DropzoneContainer = exports.DeleteImageOverlay = exports.DeleteImage = exports.Container = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Uploader = exports.Uploader = _styledComponents["default"].div.withConfig({
  displayName: "components__Uploader"
})(["width:100%;input{display:none;}"]);
var FileUploadLabel = exports.FileUploadLabel = _styledComponents["default"].div.withConfig({
  displayName: "components__FileUploadLabel"
})(["text-align:center;margin-bottom:10px;font-size:80%;color:#8c949d;"]);
var Container = exports.Container = _styledComponents["default"].div.withConfig({
  displayName: "components__Container"
})(["background:#fff;position:relative;display:flex;align-items:center;justify-content:center;flex-direction:column;margin:10px auto;transition:all 0.3s ease-in;"]);
var ErrorsContainer = exports.ErrorsContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__ErrorsContainer"
})(["max-width:300px;font-size:", "rem;color:red;text-align:left;"], 12 / 16);
var DeleteImage = exports.DeleteImage = _styledComponents["default"].div.withConfig({
  displayName: "components__DeleteImage"
})(["position:absolute;font-family:'helvetica',sans-serif;font-weight:lighter;top:5px;right:5px;font-size:", "rem;color:#fff;background:rgba(0,0,0,0.45);border-radius:6px;width:30px;height:30px;text-align:center;line-height:30px;cursor:pointer;"], 18 / 16);
var RemoveHeading = exports.RemoveHeading = _styledComponents["default"].div.withConfig({
  displayName: "components__RemoveHeading"
})(["font-family:'helvetica',sans-serif;font-size:", "rem;font-weight:bold;color:#1e2834;margin-bottom:20px;"], 18 / 16);
var DeleteImageOverlay = exports.DeleteImageOverlay = _styledComponents["default"].div.withConfig({
  displayName: "components__DeleteImageOverlay"
})(["background:rgba(255,255,255,0.6);padding:30px;text-align:center;height:inherit;"]);
var UploadPictureContainer = exports.UploadPictureContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__UploadPictureContainer"
})(["margin:2%;width:400px;height:240px;border:1px solid #cbd6e3;position:relative;display:block;background:", ";background-size:cover;@media (max-width:850px){width:100%;height:200px;}"], function (props) {
  return props.img ? "url(".concat(props.img, ") no-repeat 50% 50%") : "#f6f7f9";
});
var UploadPicturesWrapper = exports.UploadPicturesWrapper = _styledComponents["default"].div.withConfig({
  displayName: "components__UploadPicturesWrapper"
})(["display:flex;align-items:center;justify-content:center;flex-wrap:wrap;width:100%;max-width:850px;img{width:90%;}"]);
var PreviewContainer = exports.PreviewContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__PreviewContainer"
})(["display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex;justify-content:center;@media (max-width:850px){display:block;}"]);
var DropzoneContainer = exports.DropzoneContainer = _styledComponents["default"].div.withConfig({
  displayName: "components__DropzoneContainer"
})(["margin-bottom:5px;> div{display:block;padding:10px 20px;text-align:center;border:1px dashed gray;border-radius:3px;}p{margin:0;}"]);