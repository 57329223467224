var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Avatar = function (props) {
    return (React.createElement("svg", __assign({ width: "48", height: "48", viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_20_1530)" },
            React.createElement("rect", { width: "48", height: "48", rx: "24", fill: "#D5DFF6" }),
            React.createElement("path", { d: "M40.2411 35.7937C39.3568 33.6992 38.0736 31.7967 36.463 30.1922C34.8572 28.583 32.955 27.3 30.8614 26.4141C30.8426 26.4047 30.8239 26.4 30.8051 26.3906C33.7255 24.2812 35.6239 20.8453 35.6239 16.9688C35.6239 10.5469 30.4208 5.34375 23.9989 5.34375C17.577 5.34375 12.3739 10.5469 12.3739 16.9688C12.3739 20.8453 14.2723 24.2812 17.1926 26.3953C17.1739 26.4047 17.1551 26.4094 17.1364 26.4187C15.0364 27.3047 13.152 28.575 11.5348 30.1969C9.92566 31.8027 8.64267 33.7049 7.75671 35.7984C6.88634 37.8481 6.41694 40.0455 6.3739 42.2719C6.37265 42.3219 6.38142 42.3717 6.39971 42.4183C6.41799 42.4648 6.44541 42.5073 6.48036 42.5431C6.51531 42.5789 6.55707 42.6074 6.60319 42.6268C6.64931 42.6463 6.69885 42.6563 6.7489 42.6562H9.5614C9.76765 42.6562 9.93171 42.4922 9.9364 42.2906C10.0301 38.6719 11.4833 35.2828 14.052 32.7141C16.7098 30.0563 20.2395 28.5938 23.9989 28.5938C27.7583 28.5938 31.288 30.0563 33.9458 32.7141C36.5145 35.2828 37.9676 38.6719 38.0614 42.2906C38.0661 42.4969 38.2301 42.6562 38.4364 42.6562H41.2489C41.2989 42.6563 41.3485 42.6463 41.3946 42.6268C41.4407 42.6074 41.4825 42.5789 41.5174 42.5431C41.5524 42.5073 41.5798 42.4648 41.5981 42.4183C41.6164 42.3717 41.6252 42.3219 41.6239 42.2719C41.577 40.0312 41.113 37.8516 40.2411 35.7937V35.7937ZM23.9989 25.0312C21.8473 25.0312 19.8223 24.1922 18.2989 22.6688C16.7755 21.1453 15.9364 19.1203 15.9364 16.9688C15.9364 14.8172 16.7755 12.7922 18.2989 11.2687C19.8223 9.74531 21.8473 8.90625 23.9989 8.90625C26.1505 8.90625 28.1755 9.74531 29.6989 11.2687C31.2223 12.7922 32.0614 14.8172 32.0614 16.9688C32.0614 19.1203 31.2223 21.1453 29.6989 22.6688C28.1755 24.1922 26.1505 25.0312 23.9989 25.0312Z", fill: "#3956A7" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_20_1530" },
                React.createElement("rect", { width: "48", height: "48", rx: "24", fill: "white" })))));
};
