var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { color } from '../system';
var FieldGroupWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: ", ";\n  ", ";\n  & .fg-error {\n    margin-top: 0.5rem;\n    color: ", ";\n    font-size: 12px;\n  }\n"], ["\n  display: flex;\n  flex-direction: ", ";\n  ", ";\n  & .fg-error {\n    margin-top: 0.5rem;\n    color: ", ";\n    font-size: 12px;\n  }\n"])), function (props) { return (props.inline ? 'row' : 'column'); }, function (props) {
    return props.fullWidth && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      width: 100%;\n    "], ["\n      width: 100%;\n    "])));
}, color.text.error);
var FieldGroup = function (_a) {
    var children = _a.children, _b = _a.inline, inline = _b === void 0 ? false : _b, _c = _a.fullWidth, fullWidth = _c === void 0 ? false : _c;
    return (React.createElement(FieldGroupWrapper, { inline: inline, fullWidth: fullWidth, "data-testid": "t-field-group" }, children));
};
export default FieldGroup;
var templateObject_1, templateObject_2;
