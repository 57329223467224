var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Check = function (props) {
    return (React.createElement("svg", __assign({ width: "15", height: "11", viewBox: "0 0 15 11", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M4.66667 8.83333L1.44951 5.61618C1.2933 5.45997 1.04003 5.45997 0.883824 5.61618L0.282843 6.21716C0.126633 6.37337 0.126633 6.62663 0.282843 6.78284L4.38382 10.8838C4.54003 11.04 4.7933 11.04 4.94951 10.8838L14.3838 1.44951C14.54 1.2933 14.54 1.04003 14.3838 0.883824L13.7828 0.282843C13.6266 0.126633 13.3734 0.126633 13.2172 0.282843L4.66667 8.83333Z" })));
};
