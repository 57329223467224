export var spacing = {
    base: 16,
    scales: {
        buttons: {
            small: [0.25, 0.75],
            medium: [0.5, 1],
            large: [0.75, 1],
            default: [0.5, 1],
        },
    },
};
var toPadding = function (base, values) {
    return values.map(function (v) { return "".concat(v * base, "px"); }).join(' ');
};
export var getSpacingScale = function (type, size) {
    var base = spacing.base, scales = spacing.scales;
    switch (size) {
        case 'small':
            return "".concat(toPadding(base, scales[type].small), " ").concat(toPadding(base, scales[type].small));
        case 'medium':
            return "".concat(toPadding(base, scales[type].medium), " ").concat(toPadding(base, scales[type].medium));
        case 'large':
            return "".concat(toPadding(base, scales[type].large), " ").concat(toPadding(base, scales[type].large));
        default:
            return "".concat(toPadding(base, scales[type].default), " ").concat(toPadding(base, scales[type].default));
    }
};
