"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifiedAddress = exports.validations = exports.invalidatePOBox = void 0;
// component dictionary api - this gets called in CP to generate validations array
// generate validations based on mask type
var validations = exports.validations = function validations(props) {
  return props.disable_po_box ? [verifiedAddress, invalidatePOBox] : [verifiedAddress];
};

// format of value stored in redux-form:
// { value: string, verified: bool }

// (value, allValues, props, name) => error
var verifiedAddress = exports.verifiedAddress = function verifiedAddress(value) {
  if (value && value.value && !value.verified) {
    return 'Please select a verified address';
  }
};
var invalidatePOBox = exports.invalidatePOBox = function invalidatePOBox(value) {
  if (value && value.value && /^PO Box/i.test(value.value)) return 'We apologize that PO Box addresses are not allowed. Please enter another address';
};