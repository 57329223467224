export { selectOptionsByLabel } from './events';
export { makeMockReduxProvider } from './redux';
export const isTestingEnvironment = process.env.NODE_ENV === 'test';
/**
 * Returns a copy of the given translations object with values replaced with the
 * full dot-path of each key. This is used to build mock translation objects so
 * that we can run real translation functions in unit tests that return the requested
 * key of each translation string.
 *
 * Example:
 *
 *   createTestingI18nObject({
 *     common: {
 *       myTranslation: 'This is my translation',
 *     },
 *   })
 *   // outputs:
 *   {
 *     common: {
 *       myTranslation: 'common.myTranslation',
 *     },
 *   }
 *
 * @param source The source translations object (ex: the entire en_US object)
 * @param prefix The prefix to assign to all values in the output (used for
 *   recursion)
 * @returns A copy of `source` with values set to their full dot-path.
 */
export const createTestingI18nObject = (source, prefix = '') => Object.entries(source).reduce((output, [key, value]) => {
    if (typeof value === 'string') {
        output[key] = `${prefix}${key}`;
    }
    else {
        output[key] = createTestingI18nObject(value, `${prefix}${key}.`);
    }
    return output;
}, {});
/**
 * A helper class for creating promises that can easily be resolved and
 *   rejected.
 *
 * @example
 * const source = new MockPromiseSource<number>();
 * source.promise.then(val => console.log(val))
 * source.resolve(5) // will log `5` to the console asynchronously
 */
export class MockPromiseSource {
    constructor() {
        let res;
        let rej;
        this.promise = new Promise((resolve, reject) => {
            res = resolve;
            rej = reject;
        });
        this.resolve = res;
        this.reject = rej;
    }
}
