"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.atLeastOneFileUploaded = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _i18n = require("../../i18n");
var validations = exports.validations = function validations(_ref) {
  var required = _ref.required;
  return required ? [atLeastOneFileUploaded] : [];
};
var atLeastOneFileUploaded = exports.atLeastOneFileUploaded = function atLeastOneFileUploaded(v) {
  if (v) {
    if (_lodash["default"].isUndefined(v) || v.length === 0) {
      return (0, _i18n.globalTranslate)('fields.fileUpload.uploadAFile');
    }
  }
  return undefined;
};