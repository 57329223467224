"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Modal = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Modal = exports.Modal = _styledComponents["default"].div.withConfig({
  displayName: "components__Modal"
})(["height:100vh;width:100vw;position:fixed;top:0;left:0;display:flex;align-items:center;align-content:center;z-index:9999999999;> div{display:flex;flex-direction:column;border-radius:3px;padding:20px;z-index:9999999999;background:#fff;margin:0 auto;}"]);
Modal.Bg = _styledComponents["default"].div.withConfig({
  displayName: "components__Bg"
})(["z-index:9999999999;background:rgb(0,0,0,0.5);position:fixed;top:0;left:0;bottom:0;right:0;width:100vw;height:100vh;"]);