import { useEffect, useRef } from 'react';
/**
 * Converts an object to a query string.
 *
 * @param params - An object of key/value pairs. The values can be arrays of
 *   strings, in which case the key is set to each value individually (i.e.
 *   there are multiple of the same value).
 * @returns - If `params` is empty, an empty string; otherwise, the query string
 *   with a '?' prepended.
 */
export const objectToQueryString = (params) => {
    const urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
            for (const item of value) {
                urlParams.append(key, item);
            }
        }
        else {
            urlParams.set(key, value);
        }
    }
    const paramString = urlParams.toString();
    return paramString ? `?${paramString}` : '';
};
/**
 * Returns the given URL with query parameters from `params` appended. If
 *   `params` is empty, it will return the URL with no changes.
 *
 * @param url - url to append query parameters to
 * @param params - key value pairs to be converted to query parameters
 *
 * @example
 * urlWithQueryParams('/path', { test: 'some_test' })
 * // returns
 * "/path?test=some_test"
 *
 * urlWithQueryParams('/path', { test: ['a', 'b'] })
 * // returns
 * "/path?test=a&test=b"
 *
 * urlWithQueryParams('/path', {})
 * // returns
 * "/path"
 */
export const urlWithQueryParams = (url, params) => `${url}${objectToQueryString(params)}`;
/**
 * Returns the previous value passed into the hook, or `undefined` the first
 *   time it is called.
 */
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
// TODO: Upgrade webpack so that we can use "exports" in package.json and
//   properly split up the package into multiple files/directories without
//   needing to type out the raw path (including lib/) during import.
