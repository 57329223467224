export var palette = {
    white: '#FFFFFF',
    black: '#000000',
    cool_gray: '#F8FAFB',
    gray: {
        '5': "#f7f7f7",
        '10': "#ebebeb",
        '20': "#e2e2e2",
        '30': "#cbcbcb",
        '40': "#adadad",
        '50': "#737373",
        '60': "#5e5e5e",
        '70': "#474747",
        '80': "#2e2e2e",
        '90': "#191919"
    },
    yellow: {
        '5': "#fffae5",
        '10': "#fff5cc",
        '20': "#ffea9b",
        '30': "#ffe275",
        '40': "#ffd847",
        '50': "#ffcb05",
        '60': "#d2ab13",
        '70': "#ae8f13",
        '80': "#7d6712",
        '90': "#4c3f0b"
    },
    blue: {
        '5': "#EBEFFF",
        '10': "#D5DFF6",
        '20': "#AFC3EE",
        '30': "#8199D9",
        '40': "#3956A7",
        '45': '#162D88',
        '50': "#162D6D",
        '60': "#19274C",
        '70': "#182139",
        '80': "#141A29",
        '90': "#0D111B"
    },
    red: {
        '5': "#FFEBEB",
        '10': "#F5D9D6",
        '20': "#EBB4AD",
        '30': "#E08C81",
        '40': "#D7695B",
        '50': "#BE322E",
        '60': "#9B1B17",
        '70': "#760D0A",
        '80': "#620704",
        '90': "#4F0401"
    },
    orange: {
        '5': "#FFF3E5",
        '10': "#FFE7CC",
        '20': "#FED19A",
        '30': "#FFE275",
        '40': "#FAA438",
        '50': "#F69904",
        '60': "#CE7C09",
        '70': "#935D10",
        '80': "#643F0C",
        '90': "#402808"
    },
    green: {
        '5': "#EFF5F2",
        '10': "#D7EAE1",
        '20': "#ACDCC6",
        '30': "#86CAAA",
        '40': "#35A76E",
        '50': "#017A47",
        '60': "#04623A",
        '70': "#044D2E",
        '80': "#103823",
        '90': "#OC2C1B"
    },
    purple: {
        '5': "#FBEBFF",
        '10': "#EFD7F4",
        '20': "#DCB2E6",
        '30': "#C890D5",
        '40': "#B66BC7",
        '50': "#9E47B2",
        '60': "#8B2DA0",
        '70': "#791E8E",
        '80': "#5E0F70",
        '90': "#3F064B"
    },
    turquiose: {
        '5': "#EBFAFF",
        '10': "#D5EDF6",
        '20': "#AFDAE9",
        '30': "#8AC6DB",
        '40': "#63B2CF",
        '50': "#429DBD",
        '60': "#207FA1",
        '70': "#146F8F",
        '80': "#095B78",
        '90': "#02374A"
    },
    seaGreen: {
        '5': "#EBFFFD",
        '10': "#D5F6F4",
        '20': "#ACECE6",
        '30': "#83E2D9",
        '40': "#23C7B9",
        '50': "#09B5A6",
        '60': "#009F92",
        '70': "#008579",
        '80': "#00665D",
        '90': "#004C46"
    }
};
