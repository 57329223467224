var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var CheckCircleFilled = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: props.fill || 'currentColor', xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.25 9C0.25 4.16797 4.16797 0.25 9 0.25C13.832 0.25 17.75 4.16797 17.75 9C17.75 13.832 13.832 17.75 9 17.75C4.16797 17.75 0.25 13.832 0.25 9ZM12.5412 5.60068C12.6978 5.44408 12.9518 5.44453 13.1078 5.60168L13.7192 6.21733C13.8744 6.3737 13.874 6.62619 13.7182 6.78201L7.61618 12.884C7.45997 13.0402 7.2067 13.0402 7.05049 12.884L4.28284 10.1163C4.12663 9.96013 4.12663 9.70687 4.28284 9.55066L4.8927 8.9408C5.0487 8.7848 5.30154 8.78456 5.45784 8.94025L7.33333 10.8085L12.5412 5.60068Z" })));
};
