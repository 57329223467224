"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAllQuestionsAnswered = exports.findUnansweredQuestionIndexes = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.array.concat.js");
var _validations = require("../common/validations");
/*
  generates a list of validations based on the field configuration, which get passed into the redux field
*/
var _default = exports["default"] = function _default(props) {
  return [_validations.validateIsObject, validateAllQuestionsAnswered(props), (0, _validations.validateHasAggregateValue)(props), (0, _validations.validateSum)(props)];
};
var findUnansweredQuestionIndexes = exports.findUnansweredQuestionIndexes = function findUnansweredQuestionIndexes(_ref) {
  var name = _ref.name,
    questions = _ref.questions,
    value = _ref.value;
  if (!value) {
    return [];
  }
  var valueKeys = Object.keys(value);
  return questions.map(function (q, index) {
    return index;
  }).filter(function (index) {
    return !valueKeys.includes("".concat(name, "_").concat(index));
  });
};
var validateAllQuestionsAnswered = exports.validateAllQuestionsAnswered = function validateAllQuestionsAnswered(_ref2) {
  var name = _ref2.name,
    questions = _ref2.questions;
  return function (value) {
    if (findUnansweredQuestionIndexes({
      name: name,
      questions: questions,
      value: value
    }).length > 0) {
      return 'Not all questions have been answered';
    }
  };
};