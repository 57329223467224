var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';
import { color } from '../system';
var variants = {
    primary: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.brand.secondary, color.text.white),
    secondary: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.icon.disabled, color.text.white),
    success: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.success, color.text.white),
    warning: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.warning, color.text.white),
    error: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.error, color.text.white),
};
var Lightvariants = {
    primary: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.informational_subdued, color.status.informational),
    secondary: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    background-color: transparent;\n    color: ", ";\n  "], ["\n    background-color: transparent;\n    color: ", ";\n  "])), color.text.secondary),
    success: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.success_subdued, color.status.success),
    warning: css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.warning_subdued, color.status.warning),
    error: css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    background-color: ", ";\n    color: ", ";\n  "], ["\n    background-color: ", ";\n    color: ", ";\n  "])), color.status.error_subdued, color.status.error),
};
var getVariant = function (variant, light) {
    if (light === void 0) { light = false; }
    if (light) {
        return Lightvariants === null || Lightvariants === void 0 ? void 0 : Lightvariants[variant];
    }
    return variants === null || variants === void 0 ? void 0 : variants[variant];
};
var ChipWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background-color: ", ";\n  position: relative;\n  display: inline-flex;\n  border-radius: 24px;\n  font-size: ", "rem;\n  padding: ", ";\n  ", ";\n  & svg {\n    margin-left: 4px;\n    margin-right: 4px;\n  }\n"], ["\n  background-color: ", ";\n  position: relative;\n  display: inline-flex;\n  border-radius: 24px;\n  font-size: ", "rem;\n  padding: ", ";\n  ", ";\n  & svg {\n    margin-left: 4px;\n    margin-right: 4px;\n  }\n"])), color.status.error, 14 / 16, function (_a) {
    var size = _a.size;
    return size === 'medium' ? '0px 8px 0px 8px' : '8px 12px 8px 12px';
}, function (_a) {
    var variant = _a.variant, light = _a.light;
    return (variant ? getVariant(variant, light) : '');
});
export var Chip = React.forwardRef(function (_a, ref) {
    var children = _a.children, _b = _a.size, size = _b === void 0 ? 'medium' : _b, _c = _a.variant, variant = _c === void 0 ? 'primary' : _c, _d = _a.light, light = _d === void 0 ? false : _d, rest = __rest(_a, ["children", "size", "variant", "light"]);
    return (React.createElement(ChipWrapper, __assign({ ref: ref, size: size, variant: variant, light: light }, rest), children));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
