var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled, { css } from 'styled-components';
import { AnimationWrapper } from '../Transition';
import { Transition } from '@headlessui/react';
import { color } from '../system';
var defaultVariant = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n\n"], ["\n  background: ", ";\n\n"])), color.status.informational);
var errorVariant = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n"], ["\n  background: ", ";\n"])), color.status.error);
var BannerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  color: ", ";\n  padding: 1rem;\n"], ["\n  ", ";\n  ", ";\n  color: ", ";\n  padding: 1rem;\n"])), function (props) { return props.variant === 'informational' && defaultVariant; }, function (props) { return props.variant === 'error' && errorVariant; }, color.text.white);
var Banner = function (_a) {
    var _b = _a.show, show = _b === void 0 ? false : _b, _c = _a.variant, variant = _c === void 0 ? 'informational' : _c, rest = __rest(_a, ["show", "variant"]);
    return (React.createElement(AnimationWrapper, null,
        React.createElement(Transition, { leave: "transition ease-in duration-50", leaveFrom: "opacity-100", leaveTo: "opacity-0", enter: "transition ease-in duration-50", enterFrom: "opacity-0", enterTo: "opacity-100", show: show },
            React.createElement(BannerWrapper, __assign({ variant: variant }, rest)))));
};
export default Banner;
var templateObject_1, templateObject_2, templateObject_3;
