"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRef = void 0;
/**
 * Helper to set the value of a ref, whether it's a mutable ref object created
 *   with `React.createRef` or a callback.
 * @type {<T>(ref: React.ForwardedRef<T>, value: T | null) => void}
 */
var setRef = exports.setRef = function setRef(ref, value) {
  if (typeof ref === 'function') {
    ref(value);
  } else {
    ref.current = value;
  }
};