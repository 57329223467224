"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateMetricHeight = exports.validateImperialHeight = exports["default"] = void 0;
require("core-js/modules/es.function.name.js");
var _i18n = require("../../i18n");
// Validate that height is with in the same range that is used for imperial
// height measurement.
var validateMetricHeight = exports.validateMetricHeight = function validateMetricHeight(_ref) {
  var name = _ref.name;
  return function (value) {
    if (value && value["".concat(name, "_units")] === 'centimeters' && (value[name] < 91 || value[name] > 243)) {
      return (0, _i18n.globalTranslate)('validations.heightInternational.metricErroMsg');
    }
    return undefined;
  };
};

// Test to see if inches exists.
var validateImperialHeight = exports.validateImperialHeight = function validateImperialHeight(_ref2) {
  var name = _ref2.name;
  return function (value) {
    if (value && value["".concat(name, "_units")] === 'inches' && !value[name]) {
      return (0, _i18n.globalTranslate)('validations.heightInternational.imperialErrorMsg');
    }
    return undefined;
  };
};
var HeightValidations = function HeightValidations(props) {
  return [validateMetricHeight(props), validateImperialHeight(props)];
};
var _default = exports["default"] = HeightValidations;