"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropzoneText = exports.DropzoneContainer = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var DropzoneContainer = exports.DropzoneContainer = _styledComponents["default"].div.withConfig({
  displayName: "styles__DropzoneContainer"
})(["margin-bottom:8px;& #file-dropzone{display:block;padding:10px 20px;text-align:center;border:1px dashed gray;border-radius:3px;}"]);
var DropzoneText = exports.DropzoneText = _styledComponents["default"].p.withConfig({
  displayName: "styles__DropzoneText"
})(["margin:0;"]);