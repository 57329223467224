var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ChevronLeft = function (props) {
    return (React.createElement("svg", __assign({ width: "7", height: "10", viewBox: "0 0 7 10", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M5.54216 0.282843C5.69837 0.126633 5.95163 0.126633 6.10784 0.282843L6.71747 0.892466C6.87355 1.04855 6.87369 1.30158 6.71777 1.45784L3.18333 5L6.71777 8.54216C6.87369 8.69842 6.87355 8.95145 6.71747 9.10753L6.10784 9.71716C5.95163 9.87337 5.69837 9.87337 5.54216 9.71716L1.10784 5.28284C0.951633 5.12663 0.951633 4.87337 1.10784 4.71716L5.54216 0.282843Z" })));
};
