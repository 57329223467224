var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import styled, { css } from 'styled-components';
import { color } from '../system';
var LabelWrapper = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 400;\n  color: ", ";\n  margin-bottom: 6px;\n  font-size: 1rem;\n  margin-right: 4px;\n  user-select: none;\n  &:before {\n    content: '* ';\n    display: ", ";\n  }\n  ", "\n"], ["\n  font-weight: 400;\n  color: ", ";\n  margin-bottom: 6px;\n  font-size: 1rem;\n  margin-right: 4px;\n  user-select: none;\n  &:before {\n    content: '* ';\n    display: ", ";\n  }\n  ", "\n"])), color.text.default, function (props) { return (props.required ? 'inline' : 'none'); }, function (props) {
    return props.hidden && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      width: 1px;\n      height: 1px;\n      padding: 0;\n      margin: -1px;\n      overflow: hidden;\n      clip: rect(0, 0, 0, 0);\n      border: 0;\n    "], ["\n      position: absolute;\n      width: 1px;\n      height: 1px;\n      padding: 0;\n      margin: -1px;\n      overflow: hidden;\n      clip: rect(0, 0, 0, 0);\n      border: 0;\n    "])));
});
var Label = React.forwardRef(function (props, ref) {
    return React.createElement(LabelWrapper, __assign({ ref: ref }, props));
});
export default Label;
var templateObject_1, templateObject_2;
