"use strict";

require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.weak-map.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.object.define-property.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveButton = exports.RangeHeaderContainer = exports.RadioCheckbox = exports.PadHolder = exports.OuterUl = exports.Li = exports.InnerUl = exports.InnerLi = exports.InnerButton = exports.GroupListHolder = exports.CustomInputStyles = exports.AddButton = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.split.js");
var _formElements = require("@evidation/form-elements");
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) { if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } } return n["default"] = e, t && t.set(e, n), n; }
var inputSelectedColor = "#05456d";
var InnerUl = exports.InnerUl = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__InnerUl"
})(["list-style:none;margin:0;padding:0;li{.draggable{display:none;&.isDraggable{display:unset;position:absolute;left:5px;bottom:5px;}}}"]);
var OuterUl = exports.OuterUl = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__OuterUl"
})(["list-style:none;margin:0;padding:0;"]);
var Li = exports.Li = _styledComponents["default"].li.withConfig({
  displayName: "commonComponents__Li"
})(["position:relative;margin:0;padding:0;"]);
var InnerLi = exports.InnerLi = _styledComponents["default"].li.withConfig({
  displayName: "commonComponents__InnerLi"
})(["position:relative;display:grid;grid-template-columns:auto 30px;grid-template-rows:auto;padding:5px 0 0 5px;background-color:transparent;margin-bottom:8px;&:hover{background-color:rgba(12,39,48,0.25);}"]);
var AddButton = exports.AddButton = (0, _styledComponents["default"])(_formElements.Button).withConfig({
  displayName: "commonComponents__AddButton"
})(["font-size:", "rem;width:70px;cursor:pointer;padding:4px 0;margin:10px 0;"], 10 / 16);
var InnerButton = exports.InnerButton = _styledComponents["default"].button.withConfig({
  displayName: "commonComponents__InnerButton"
})(["grid-column:2;grid-row:1;background:none;border:0;margin:0;border-radius:3px;cursor:pointer;&:hover{background:linear-gradient(-180deg,#28b90c 0%,#27b30b 100%);}&:disabled{cursor:default;&:hover{background:inherit;}}"]);
var RemoveButton = exports.RemoveButton = _styledComponents["default"].button.withConfig({
  displayName: "commonComponents__RemoveButton"
})(["background:none;border:0;position:absolute;top:20px;right:10px;line-height:1em;color:#0c4e69;cursor:pointer;vertical-align:middle;&:hover{text-decoration:none;}"]);
var CustomInputStyles = exports.CustomInputStyles = (0, _styledComponents.css)(["input[type='checkbox'],input[type='radio']{position:relative;appearance:none;cursor:pointer;min-width:16px;height:16px;border-radius:3px;border:1px solid #ddd;background:#fff;box-sizing:border-box;margin:0;&::after{content:'';position:absolute;box-sizing:border-box;transition:all 0.2s ease;}+ label{position:relative;padding-left:8px;cursor:pointer;line-height:20px;display:inline-block;font-size:medium;}&::disabled{cursor:default;+ label{cursor:default;}}}input[type='checkbox']{&::after{left:4px;top:1px;width:6px;height:10px;border:solid ", ";border-width:0 2px 2px 0;transform:rotate(45deg);}&:not(:checked)::after{transform:rotate(45deg) scale(0);}&:checked::after{transform:rotate(45deg) scale(1);}}input[type='radio']{border-radius:50%;background:linear-gradient(to bottom,#ffffff 0%,#e8e9e8 98%);&::after{left:3px;top:3px;width:8px;height:8px;background:", ";border-radius:50%;}&:checked{background:#fdfdfd;}&:not(:checked)::after{transform:scale(0);}&:checked::after{transform:scale(1);}}label p{font-size:1rem;line-height:1.2;padding-top:1px;}"], inputSelectedColor, inputSelectedColor);
var RadioCheckbox = exports.RadioCheckbox = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__RadioCheckbox"
})(["text-align:", ";display:", ";", ";"], function (props) {
  return props.split ? "center" : "normal";
}, function (props) {
  return props.split ? "inline-block" : "inherit";
}, function (props) {
  return props.split && "width: ".concat(props.split ? "".concat(100 / props.options.length, "%") : "100%");
});
var PadHolder = exports.PadHolder = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__PadHolder"
})(["position:relative;width:100%;height:250px;background-color:#fff;box-shadow:0 1px 4px rgba(0,0,0,0.27),0 0 40px rgba(0,0,0,0.08) inset;border-radius:4px;& canvas{position:absolute;left:0;right:0;bottom:0;top:0;width:100%;height:100%;border-radius:4px;}"]);
var GroupListHolder = exports.GroupListHolder = _styledComponents["default"].div.withConfig({
  displayName: "commonComponents__GroupListHolder"
})(["display:flex;justify-content:space-around;flex-direction:", ";.responsive-input-label{display:", ";}> div{display:flex;flex-direction:column;}@media only screen and (max-width:760px),(min-device-width:768px) and (max-device-width:1024px){flex-direction:column;.responsive-input-label{font-size:", "rem;color:#395865;}}"], function (props) {
  return props.displayRow ? "row" : "column";
}, function (props) {
  return props.allowResponsiveLabel ? "none" : "inherit";
}, 14 / 16);
GroupListHolder.defaultProps = {
  allowResponsiveLabel: true,
  displayRow: false
};
var RangeHeaderContainer = exports.RangeHeaderContainer = _styledComponents["default"].ul.withConfig({
  displayName: "commonComponents__RangeHeaderContainer"
})(["display:flex;padding:0 14px 0 12px !important;> div,> li{flex-grow:1;flex-basis:0;list-style:none;padding:0 3px;text-align:center;}"]);