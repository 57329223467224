"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateEquality = exports.emailValidation = exports.emailRegex = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.array.concat.js");
var _lodash = _interopRequireDefault(require("lodash"));
var _utils = require("../../i18n/utils");
var emailRegex = exports.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var validateEmail = function validateEmail() {
  var email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return email.match(emailRegex);
};
var validateEquality = exports.validateEquality = function validateEquality(value, allValues, props, name) {
  var ext = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "_confirm";
  var errorMessage = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : undefined;
  if (_lodash["default"].has(allValues, name) && _lodash["default"].has(allValues, "".concat(name).concat(ext))) {
    if (!_lodash["default"].isEqual(allValues[name], allValues["".concat(name).concat(ext)])) {
      if (errorMessage) {
        return errorMessage;
      }
      return (0, _utils.globalTranslate)('validations.pleaseConfirmEmail');
    }
  }
};
var emailValidation = exports.emailValidation = function emailValidation(v) {
  if (v && !validateEmail(v)) {
    return (0, _utils.globalTranslate)('validations.invalidEmail');
  }
  return undefined;
};