"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateNumbersOnly = exports.validateNumberInRange = exports.validateLettersOnly = exports.validateLength = exports.validateAlphanumeric = void 0;
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.parse-int.js");
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _lodash = _interopRequireDefault(require("lodash"));
var _excluded = ["mask_custom_type", "mask_custom_restrict_input"];
/*
  Redux-form Field Validations
  generates a list of validations based on the field configuration, which get passed into the redux field
*/
var validations = exports.validations = function validations(_ref) {
  var mask_custom_type = _ref.mask_custom_type,
    mask_custom_restrict_input = _ref.mask_custom_restrict_input,
    props = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
  switch (mask_custom_type) {
    case 'alphanumeric':
    case 'length':
      switch (mask_custom_restrict_input) {
        case 'numeric':
          return [validateNumbersOnly(props), validateLength(props)];
        case 'letter':
          return [validateLettersOnly(props), validateLength(props)];
        case 'alphanumeric':
        default:
          return [validateLength(props), validateAlphanumeric(props)];
      }
    case 'numeric':
      return [validateNumberInRange(props)];
    default:
      return [];
  }
};
var validateLength = exports.validateLength = function validateLength(_ref2) {
  var mask_custom_min = _ref2.mask_custom_min,
    mask_custom_max = _ref2.mask_custom_max,
    _ref2$prefix = _ref2.prefix,
    prefix = _ref2$prefix === void 0 ? '' : _ref2$prefix,
    custom_validation_message = _ref2.custom_validation_message;
  return function (value) {
    if (value) {
      var min = mask_custom_min + prefix.length;
      var max = mask_custom_max + prefix.length;
      if (min <= value.trim().length && value.trim().length <= max) return undefined;else return custom_validation_message ? custom_validation_message : validateLengthMessage(mask_custom_min, mask_custom_max);
    }
  };
};
var validateLengthMessage = function validateLengthMessage(min, max) {
  return min === max ? "Please respond with a ".concat(min, " character answer.") : "Please respond with a ".concat(min, " to ").concat(max, " character answer.");
};
var validateLettersOnly = exports.validateLettersOnly = function validateLettersOnly(_ref3) {
  var custom_validation_message = _ref3.custom_validation_message,
    _ref3$prefix = _ref3.prefix,
    prefix = _ref3$prefix === void 0 ? '' : _ref3$prefix;
  return function (value) {
    if (value) {
      var valMinusPrefix = value.slice(prefix.length);
      if (/^[a-z]+$/i.test(valMinusPrefix)) return undefined;else return custom_validation_message ? custom_validation_message : "Please respond with letters only.";
    }
  };
};
var validateNumbersOnly = exports.validateNumbersOnly = function validateNumbersOnly(_ref4) {
  var custom_validation_message = _ref4.custom_validation_message,
    _ref4$prefix = _ref4.prefix,
    prefix = _ref4$prefix === void 0 ? '' : _ref4$prefix;
  return function (value) {
    if (value) {
      var valMinusPrefix = value.slice(prefix.length);
      if (/^\d+$/.test(valMinusPrefix)) return undefined;else return custom_validation_message ? custom_validation_message : "Please respond with numbers only.";
    }
  };
};
var validateAlphanumeric = exports.validateAlphanumeric = function validateAlphanumeric(_ref5) {
  var custom_validation_message = _ref5.custom_validation_message,
    _ref5$prefix = _ref5.prefix,
    prefix = _ref5$prefix === void 0 ? '' : _ref5$prefix;
  return function (value) {
    if (value) {
      var valMinusPrefix = value.slice(prefix.length);
      if (/^\w+$/i.test(valMinusPrefix)) return undefined;else return custom_validation_message ? custom_validation_message : "Please respond with an alphanumeric answer.";
    }
  };
};
var validateNumberInRange = exports.validateNumberInRange = function validateNumberInRange(_ref6) {
  var mask_custom_min = _ref6.mask_custom_min,
    mask_custom_max = _ref6.mask_custom_max,
    custom_validation_message = _ref6.custom_validation_message,
    _ref6$prefix = _ref6.prefix,
    prefix = _ref6$prefix === void 0 ? '' : _ref6$prefix;
  return function (value) {
    if (value) {
      var valMinusPrefix = value.slice(prefix.length);
      var parsedValue = parseInt(valMinusPrefix, 10);
      if (mask_custom_min <= parsedValue && parsedValue <= mask_custom_max) return undefined;else return custom_validation_message ? custom_validation_message : "Please respond with a number between ".concat(mask_custom_min, "-").concat(mask_custom_max, ".");
    }
  };
};