"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _testingUtils = require("@evidation/testing-utils");
var _enUS = require("./enUS");
var testingTranslations = (0, _testingUtils.createTestingI18nObject)(_enUS.enUS);
var _default = exports["default"] = testingTranslations;