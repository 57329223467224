import { merge } from 'lodash';
import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
/**
 * Returns a simple wrapper component that provides a built-in, ready-to-use
 *   Redux store for unit testing.
 *
 * Example:
 *
 * ```
 * const MockProvider = makeMockReduxProvider(rootReducer, middleware)
 * render(
 *   <MockProvider initialState={fullState} stateOverrides={partialState}>
 *     <MyConnectedComponent />
 *   </MockProvider>
 * )
 * ```
 *
 * @param reducer The root reducer of the Redux store.
 * @param [middleware] Any middleware you want to enable for this instance.
 */
export const makeMockReduxProvider = (reducer, middleware) => {
    /**
     * @param props
     * @param props.initialState The starting state of the Redux store. This
     *   overrides the entire initial state, so use it sparingly.
     * @param props.stateOverrides Allows you to override individual values within
     *   the overall Redux state.
     */
    const MockReduxProvider = ({ children, initialState, stateOverrides, }) => {
        if (stateOverrides) {
            if (!initialState) {
                initialState = reducer(undefined, { type: '' });
            }
            initialState = merge(initialState, stateOverrides);
        }
        const store = createStore(reducer, 
        // Without the cast, the `initialState` param produces an error about
        //   mismatching types. It doesn't seem relevant so it's likely safe.
        initialState, middleware);
        return React.createElement(Provider, { store: store }, children);
    };
    return MockReduxProvider;
};
