var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var CheckCircle = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.25 9.00012C0.25 4.16809 4.16797 0.250122 9 0.250122C13.832 0.250122 17.75 4.16809 17.75 9.00012C17.75 13.8322 13.832 17.7501 9 17.7501C4.16797 17.7501 0.25 13.8322 0.25 9.00012ZM1.73438 9.00012C1.73438 13.0118 4.98828 16.2657 9 16.2657C13.0117 16.2657 16.2656 13.0118 16.2656 9.00012C16.2656 4.9884 13.0117 1.7345 9 1.7345C4.98828 1.7345 1.73438 4.9884 1.73438 9.00012ZM12.5413 5.60056C12.6979 5.44396 12.9519 5.4444 13.108 5.60156L13.7193 6.21721C13.8745 6.37357 13.8741 6.62607 13.7183 6.78189L7.6163 12.8839C7.46009 13.0401 7.20682 13.0401 7.05061 12.8839L4.28296 10.1162C4.12676 9.96001 4.12675 9.70675 4.28296 9.55054L4.89283 8.94067C5.04882 8.78468 5.30167 8.78443 5.45796 8.94013L7.33346 10.8084L12.5413 5.60056Z" })));
};
