import React from 'react';
import { isFragment } from 'react-is';
import Spacer from './Spacer';
/**
 * Takes an array of React nodes and returns a copy with all fragments removed,
 *   flattening each fragment's children into the array.
 *
 * @param {React.ReactNode[]} nodes The array of React nodes
 * @param options
 * @param options.keyPrefix Prepend this string to the `key` prop of every node
 *   in the new array. Used to prevent duplicate keys when flattening fragments.
 */
var flattenFragments = function (nodes, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.keyPrefix, keyPrefix = _c === void 0 ? '' : _c;
    return nodes.reduce(function (children, child) {
        if (isFragment(child)) {
            children.push.apply(children, flattenFragments(React.Children.toArray(child.props.children), {
                keyPrefix: "".concat(keyPrefix).concat(child.key || ''),
            }));
        }
        else if (!React.isValidElement(child)) {
            // We assume that you know what you're doing.
            children.push(child);
        }
        else {
            children.push(React.cloneElement(child, { key: "".concat(keyPrefix).concat(child.key) }));
        }
        return children;
    }, []);
};
/**
 * Inserts a Spacer component between each child node. Fragments are flattened,
 *   so each child within a fragment counts as its own.
 * @param props
 * @param props.size The amount of space, in pixels, between each node.
 * @param props.direction The direction in which the spacers should be placed.
 */
var SpaceBetween = function (_a) {
    var size = _a.size, _b = _a.direction, direction = _b === void 0 ? 'vertical' : _b, children = _a.children;
    var childrenArray = flattenFragments(React.Children.toArray(children));
    var childrenWithSpacers = childrenArray.reduce(function (childrenWithSpacers, child, index) {
        if (child === null || child === false || child === '') {
            return childrenWithSpacers;
        }
        if (childrenWithSpacers.length) {
            childrenWithSpacers.push(React.createElement(Spacer, { size: size, direction: direction, key: "@SpaceBetween:spacer:".concat(index) }));
        }
        childrenWithSpacers.push(child);
        return childrenWithSpacers;
    }, []);
    // The fragment isn't strictly needed here but it makes TypeScript happy.
    return React.createElement(React.Fragment, null, childrenWithSpacers);
};
export default SpaceBetween;
