import React from 'react';
/**
 * Takes up a given amount of space either vertically or horizontally.
 *
 * @param props
 * @param props.size The amount of space, in pixels, to take up.
 * @param props.direction The direction in which to take up space. Defaults to
 *   'vertical'.
 */
var Spacer = function (_a) {
    var _b;
    var size = _a.size, _c = _a.direction, direction = _c === void 0 ? 'vertical' : _c;
    return (React.createElement("div", { style: (_b = {}, _b[direction === 'vertical' ? 'height' : 'width'] = size, _b) }));
};
export default Spacer;
