import { within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
/**
 * Just like `userEvent.selectOptions`, but uses labels instead of values to
 *   find the relevant options to select.
 * @param element The <select> element that contains the options.
 * @param optionLabels The label(s) to use to find the option(s) to select. This
 *   can be a single string or array of strings.
 * @param restArgs The rest of the parameters are identical to
 *   `userEvent.selectOptions`.
 */
export const selectOptionsByLabel = (element, optionLabels, ...restArgs) => {
    if (!Array.isArray(optionLabels)) {
        optionLabels = [optionLabels];
    }
    const options = optionLabels.map((label) => within(element).getByRole('option', { name: label }));
    userEvent.selectOptions(element, options, ...restArgs);
};
