"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zipValidation = exports.validateTextOnly = exports.validateMinMaxLength = exports.validateBloodPressure = exports.validateAlphanumeric = exports.todaysDateMustBeChecked = exports.timeValidation = exports.singleCheckboxMustBeChecked = exports.selectNotDefaultValue = exports.required = exports.phone = exports.noEmptyFileArray = exports.noEmptyArray = exports.maxFilesUpload = exports.maxFileSize = exports.includedInMedicationList = exports.humanaLengthValidation = exports.emailValidation = exports.dateValidation = exports.LENGTH_VALIDATION_ERROR_MESSSAGE = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.parse-int.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.string.split.js");
require("core-js/modules/es.array.some.js");
var _constants = require("./constants");
var _i18n = require("./i18n");
var _lodash = _interopRequireDefault(require("lodash"));
var LENGTH_VALIDATION_ERROR_MESSSAGE = exports.LENGTH_VALIDATION_ERROR_MESSSAGE = function LENGTH_VALIDATION_ERROR_MESSSAGE(length, min, max) {
  return max ? (0, _i18n.globalTranslate)('validations.lengthError.max', {
    max: max,
    length: length
  }) : (0, _i18n.globalTranslate)('validations.lengthError.max', {
    min: min,
    length: length
  });
};
var validateEmail = function validateEmail(email) {
  return email.match(_constants.emailRegex);
};
var validatePhone = function validatePhone(phone) {
  return phone.match(_constants.phoneRegex);
};
var validateTime = function validateTime(time) {
  return time.match(_constants.timeRegex);
};
var validateZip = function validateZip(zip) {
  return zip.match(_constants.zipRegex);
};
var getMaxFiles = function getMaxFiles(v) {
  return parseInt(_lodash["default"].has(v[0], 'maxFiles') ? v[0].maxFiles : _constants.defaultMaxFiles, 10);
};
var required = exports.required = function required(value) {
  return !_lodash["default"].isDate(value) && !_lodash["default"].isBoolean(value) && !_lodash["default"].isNumber(value) && _lodash["default"].isEmpty(value) || _lodash["default"].isNaN(value) ? (0, _i18n.globalTranslate)('validations.required') : undefined;
};

//TODO: tech debt, check to see if this validation is used.
var includedInMedicationList = exports.includedInMedicationList = function includedInMedicationList(value) {
  return ['medication1', 'medication2'].includes(value.replace(/ /g, '').toLowerCase()) ? undefined : "This is not a valid medication";
};
var phone = exports.phone = function phone(v) {
  if (v && !validatePhone(v)) {
    return (0, _i18n.globalTranslate)('validations.phoneError');
  }
  return undefined;
};
var emailValidation = exports.emailValidation = function emailValidation(v) {
  if (v && !validateEmail(v)) {
    return (0, _i18n.globalTranslate)('validations.invalidEmail');
  }
  return undefined;
};

//TODO: tech debt research if this validation is still used
var timeValidation = exports.timeValidation = function timeValidation(v) {
  if (v && !validateTime(v)) {
    return (0, _i18n.globalTranslate)('validations.timeValidation');
  }
  return undefined;
};
var dateValidation = exports.dateValidation = function dateValidation() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  if (v && "".concat(v).toLowerCase() === "invalid date") {
    return (0, _i18n.globalTranslate)('validations.dateValidation');
  }
  return undefined;
};
var zipValidation = exports.zipValidation = function zipValidation(v) {
  if (v) {
    if (v.length !== 5 || !validateZip(v)) {
      return (0, _i18n.globalTranslate)('validations.zipValidation');
    }
  }
  return undefined;
};
var noEmptyArray = exports.noEmptyArray = function noEmptyArray(v) {
  if (v && _lodash["default"].isEmpty(v)) {
    return (0, _i18n.globalTranslate)('validations.required');
  }
  return undefined;
};
var selectNotDefaultValue = exports.selectNotDefaultValue = function selectNotDefaultValue(v) {
  if (v && _lodash["default"].isEqual(v, _constants.default_select_value)) {
    return (0, _i18n.globalTranslate)('validations.required');
  }
  return undefined;
};
var noEmptyFileArray = exports.noEmptyFileArray = function noEmptyFileArray(v) {
  if (v && _lodash["default"].isEmpty(v)) {
    var maxFiles = getMaxFiles(v);
    return (0, _i18n.globalTranslate)('validations.noEmptyArray', {
      maxFiles: maxFiles
    });
  }
  return undefined;
};
var maxFilesUpload = exports.maxFilesUpload = function maxFilesUpload(v) {
  if (!_lodash["default"].isEmpty(v)) {
    var maxFiles = getMaxFiles(v);
    if (v.length > maxFiles) {
      return (0, _i18n.globalTranslate)('validations.maxFilesUpload');
    }
  }
  return undefined;
};
var maxFileSize = exports.maxFileSize = function maxFileSize(v) {
  var output = !_lodash["default"].isEmpty(v) ? _lodash["default"].reduce(v, function (acc, file) {
    return file.exceedsFileSize ? [].concat((0, _toConsumableArray2["default"])(acc), [file.name]) : (0, _toConsumableArray2["default"])(acc);
  }, []) : [];
  var formattedOutput = output.join(", ");
  return output.length > 0 ? (0, _i18n.globalTranslate)('validations.maxFileSize', {
    fileList: formattedOutput
  }) : undefined;
};
var humanaLengthValidation = exports.humanaLengthValidation = function humanaLengthValidation(v) {
  if (!_lodash["default"].isEmpty(v) && v !== "false") {
    var val = v.trim().replace(_constants.regexUnderscores, "");
    if (val.length < _constants.HumanaIDLength) {
      return (0, _i18n.globalTranslate)('validations.humanaLengthValidation', {
        length: _constants.HumanaIDLength - 1
      });
    }
  }
  return undefined;
};
var singleCheckboxMustBeChecked = exports.singleCheckboxMustBeChecked = function singleCheckboxMustBeChecked(v) {
  if (v === true) {
    return undefined;
  }
  return (0, _i18n.globalTranslate)('validations.mustCheck');
};
var todaysDateMustBeChecked = exports.todaysDateMustBeChecked = function todaysDateMustBeChecked(v) {
  if (!!v && _lodash["default"].isString(v) && !_lodash["default"].isEmpty(v)) {
    return undefined;
  }
  return (0, _i18n.globalTranslate)('validations.mustCheck');
};
var normalizeMask = function normalizeMask() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return value.replace(/_/g, "");
};
var validateBloodPressure = exports.validateBloodPressure = function validateBloodPressure() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var currentValue = normalizeMask(v);
  var values = currentValue.split('/').map(function (split) {
    return split.replace('mm Hg', '').trim();
  });
  if (_lodash["default"].some(values, function (v) {
    return v.length === 0;
  })) return (0, _i18n.globalTranslate)('validations.required');
  var systolic = parseInt(values[0]);
  var diastolic = parseInt(values[1], 10);
  if (systolic <= diastolic || diastolic > 130 || diastolic < 30 || systolic > 210 || systolic < 60) return (0, _i18n.globalTranslate)('validations.bpValid');
};
var validateAlphanumeric = exports.validateAlphanumeric = function validateAlphanumeric(v) {
  if (v && !v.match(_constants.alphanumericRegex)) {
    return (0, _i18n.globalTranslate)('validations.alphaNumericError');
  }
  return undefined;
};
var validateMinMaxLength = exports.validateMinMaxLength = function validateMinMaxLength(v, min, max) {
  if (v && max && v.length > parseInt(max)) {
    return LENGTH_VALIDATION_ERROR_MESSSAGE(v.length, null, max);
  }
  if (v && min && v.length < parseInt(min)) {
    return LENGTH_VALIDATION_ERROR_MESSSAGE(v.length, min, null);
  }
  return undefined;
};
var validateTextOnly = exports.validateTextOnly = function validateTextOnly(v) {
  if (v && !v.match(_constants.textOnlyRegex)) {
    return (0, _i18n.globalTranslate)('validations.textOnly');
  }
  return undefined;
};