"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stripHtml = exports.ReactMediaQueries = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _lodash = _interopRequireDefault(require("lodash"));
var ReactMediaQueries = exports.ReactMediaQueries = {
  mobile: '(max-width: 599px)',
  desktop: '(min-width: 600px)'
};
var strip_html_regex = /(<([^>]+)>)/gi;
var stripHtml = exports.stripHtml = function stripHtml() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  return string.replace(strip_html_regex, "");
};