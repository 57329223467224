"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.index-of.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.some.js");
var _i18n = require("../../i18n");
var validateFileSize = function validateFileSize(name, maxSize) {
  return function (value) {
    if (!value) return;
    var fileName = value["".concat(name, "_file_name")];
    var fileSize = value["".concat(name, "_file_size")];
    if (fileSize && fileSize > maxSize) {
      return (0, _i18n.globalTranslate)('validations.maxFileSize', {
        fileList: fileName
      });
    }
  };
};
var validateFileType = function validateFileType(name, validTypes) {
  return function (value) {
    if (!value) return;
    var types = validTypes.map(function (_ref) {
      var mime = _ref.mime;
      return mime;
    });
    var fileName = value["".concat(name, "_file_name")];
    var fileType = value["".concat(name, "_file_type")];
    if (fileType && types.indexOf(fileType) === -1) {
      var acceptedTypes = validTypes.map(function (_ref2) {
        var extension = _ref2.extension;
        return extension.toUpperCase();
      }).join(', ');
      var invalidFileParams = {
        fileName: fileName,
        acceptedTypes: acceptedTypes
      };
      if (validTypes.length > 1) {
        return (0, _i18n.globalTranslate)('invalidFileType', invalidFileParams);
      } else {
        return (0, _i18n.globalTranslate)('invalidFileTypes', invalidFileParams);
      }
    }
  };
};
var validateJson = function validateJson(name) {
  return function (value) {
    if (!value) return;
    try {
      var fileValue = value[name];
      JSON.parse(fileValue);
    } catch (err) {
      return (0, _i18n.globalTranslate)('validations.invalidJson');
    }
  };
};
var validations = exports.validations = function validations(params) {
  var name = params.name,
    maxSize = params.maxSize,
    validFileTypes = params.validFileTypes;
  var rules = [validateFileSize(name, maxSize), validateFileType(name, validFileTypes)];
  var containsJson = validFileTypes.some(function (_ref3) {
    var mime = _ref3.mime;
    return /json/i.test(mime);
  });
  if (containsJson) rules.push(validateJson(name));
  return rules;
};