"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parsePhone = exports.parseMaskedType = void 0;
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var parsePhone = exports.parsePhone = function parsePhone(number) {
  return number ? number.replace(/[^\d]/g, '').slice(0, 11) : '';
};
var parseMaskedType = exports.parseMaskedType = function parseMaskedType(v) {
  return v ? v.replace(/_/g, '') : undefined;
};