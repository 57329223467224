"use strict";

require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.define-properties.js");
require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.concat.js");
var _lodash = _interopRequireDefault(require("lodash"));
var _excluded = ["name"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var iterateOverInputs = function iterateOverInputs(arrayOfInputs) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(arrayOfInputs, function (acc, item, index) {
    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, item.name, "".concat(path, "[").concat(index, "]")));
  }, {});
};
var iterateOverSinglePage = function iterateOverSinglePage(page) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(page, function (acc, _ref, index) {
    var _ref$name = _ref.name,
      name = _ref$name === void 0 ? "" : _ref$name,
      item = (0, _objectWithoutProperties2["default"])(_ref, _excluded);
    if (!_lodash["default"].has(item, "input")) {
      return acc;
    }
    return _objectSpread(_objectSpread({}, acc), iterateOverInputs(item.input, "".concat(path, "[").concat(index, "]['input']").concat(name !== "" ? "['".concat(name, "']") : "")));
  }, {});
};
var _default = exports["default"] = function _default(pages) {
  var path = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  return _lodash["default"].reduce(pages, function (acc, page, index) {
    if (_lodash["default"].has(page, "input")) {
      // case where the form does not have nested pages
      return _objectSpread(_objectSpread({}, acc), iterateOverSinglePage([page], "".concat(path)));
    } else {
      return _objectSpread(_objectSpread({}, acc), iterateOverSinglePage(page, "".concat(path, "[").concat(index, "]")));
    }
  }, {});
};