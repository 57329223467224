var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import styled from 'styled-components';
import { range } from 'lodash';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactPaginate from 'react-paginate';
var PREV_TITLE_TEXT = 'Previous Page';
var NEXT_TITLE_TEXT = 'Next Page';
/**
 * Component that renders multiple entries one page at a time and provides a
 * pagination UI.
 *
 * Usage example:
 *
 *   <Paginator totalCount={23} pageSize={10}>
 *     {({ index, indexOnPage, page }) => (
 *       // Render each entry for the page here
 *       <div key={indexOnPage}>
 *         Page {page}, index {index}
 *       </div>
 *     )}
 *   </Paginator>
 */
var Paginator = function (_a) {
    var totalCount = _a.totalCount, pageSize = _a.pageSize, itemsContainerClassName = _a.itemsContainerClassName, children = _a.children, _b = _a.initialCurrentPage, initialCurrentPage = _b === void 0 ? 0 : _b, _c = _a.page, page = _c === void 0 ? null : _c, _d = _a.getCurrentPage, getCurrentPage = _d === void 0 ? function (data) { return data; } : _d;
    var pageCount = Math.ceil(totalCount / pageSize);
    var _e = useState(pageCount - 1 < initialCurrentPage ? pageCount - 1 : initialCurrentPage), currentPageIndex = _e[0], setCurrentPageIndex = _e[1];
    var entryCount = Math.min(totalCount - pageSize * currentPageIndex, pageSize);
    var pageStartIndex = currentPageIndex * pageSize;
    var entries = range(pageStartIndex, pageStartIndex + entryCount);
    if (pageSize < 1) {
        throw new Error('Page size must be greater than 0');
    }
    return (React.createElement(Container, { isMobile: isMobile },
        React.createElement("div", { className: itemsContainerClassName }, children &&
            entries.map(function (index, indexOnPage) {
                return children({ index: index, indexOnPage: indexOnPage, page: currentPageIndex });
            })),
        React.createElement("div", { className: "pagination-container" },
            React.createElement(ReactPaginate, { pageCount: pageCount, 
                // Note: We may want these to be customizable at some point, but the
                // need hasn't come up yet.
                pageRangeDisplayed: isMobile ? 3 : 5, marginPagesDisplayed: 1, onPageChange: function (_a) {
                    var selected = _a.selected;
                    getCurrentPage(selected);
                    setCurrentPageIndex(selected);
                }, previousLabel: React.createElement(Arrow, { title: PREV_TITLE_TEXT }), nextLabel: React.createElement(Arrow, { title: NEXT_TITLE_TEXT }), containerClassName: "pagination", pageClassName: "page", previousClassName: "page", nextClassName: "page", pageLinkClassName: "link", breakClassName: "page", breakLinkClassName: "link", previousLinkClassName: "link arrow prev", nextLinkClassName: "link arrow next", activeClassName: "active", disabledClassName: "disabled", forcePage: page ? (page - 1) : currentPageIndex }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .pagination-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .pagination {\n      display: flex;\n      align-items: center;\n      padding: 0;\n      margin: 0;\n\n      .page {\n        list-style-type: none;\n        &.disabled {\n          opacity: 0.5;\n          cursor: default;\n          .link {\n            cursor: default;\n          }\n        }\n\n        .link {\n          cursor: pointer;\n          padding: 8px 12px;\n          margin: 0 ", ";\n          text-decoration: none;\n          font-size: ", "rem;\n          line-height: 1;\n          border-radius: 3px;\n          display: flex;\n          align-items: center;\n          &.arrow.prev {\n            transform: rotate(180deg);\n          }\n        }\n      }\n    }\n  }\n  & .active {\n    background-color: #e7e7e7;\n    border-radius: 4px;\n  }\n"], ["\n  .pagination-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .pagination {\n      display: flex;\n      align-items: center;\n      padding: 0;\n      margin: 0;\n\n      .page {\n        list-style-type: none;\n        &.disabled {\n          opacity: 0.5;\n          cursor: default;\n          .link {\n            cursor: default;\n          }\n        }\n\n        .link {\n          cursor: pointer;\n          padding: 8px 12px;\n          margin: 0 ", ";\n          text-decoration: none;\n          font-size: ", "rem;\n          line-height: 1;\n          border-radius: 3px;\n          display: flex;\n          align-items: center;\n          &.arrow.prev {\n            transform: rotate(180deg);\n          }\n        }\n      }\n    }\n  }\n  & .active {\n    background-color: #e7e7e7;\n    border-radius: 4px;\n  }\n"])), function (props) { return (props.isMobile ? '0' : '5px'); }, 14 / 16);
var ArrowSvg = styled.svg(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  line {\n    stroke: black;\n    stroke-width: 1.5px;\n    stroke-linecap: round;\n  }\n"], ["\n  line {\n    stroke: black;\n    stroke-width: 1.5px;\n    stroke-linecap: round;\n  }\n"])));
var Arrow = function (props) { return (React.createElement(ArrowSvg, __assign({ width: "13", height: "16" }, props),
    React.createElement("line", { x1: "2", y1: "2", x2: "10", y2: "8" }),
    React.createElement("line", { x1: "2", y1: "14", x2: "10", y2: "8" }))); };
export default Paginator;
var templateObject_1, templateObject_2;
