"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "enUS", {
  enumerable: true,
  get: function get() {
    return _enUS.enUS;
  }
});
Object.defineProperty(exports, "esUS", {
  enumerable: true,
  get: function get() {
    return _esUS.esUS;
  }
});
Object.defineProperty(exports, "globalTranslate", {
  enumerable: true,
  get: function get() {
    return _utils.globalTranslate;
  }
});
var _enUS = require("./enUS");
var _esUS = require("./esUS");
var _utils = require("./utils");