var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Appsignal from '@appsignal/javascript';
import { plugin as consoleBC } from '@appsignal/plugin-breadcrumbs-console';
import { plugin as networkBC } from '@appsignal/plugin-breadcrumbs-network';
import { plugin as windowEvents } from '@appsignal/plugin-window-events';
import { plugin as pathDecorator } from '@appsignal/plugin-path-decorator';
import { Span } from '@appsignal/javascript/dist/cjs/span';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
var MockAppSignal = /** @class */ (function (_super) {
    __extends(MockAppSignal, _super);
    function MockAppSignal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MockAppSignal.prototype.sendError = function (error, tags, namespace) { };
    MockAppSignal.prototype.use = function (plugin) { };
    MockAppSignal.prototype.createSpan = function (fn) {
        return new Span();
    };
    MockAppSignal.prototype.send = function (error, tags, namespace) { };
    return MockAppSignal;
}(Appsignal));
var appsignal = new MockAppSignal({});
if (process.env.NODE_ENV !== 'test') {
    var env = window.env || {};
    appsignal = new Appsignal({
        key: env.APPSIGNAL,
        namespace: env.NAMESPACE,
        revision: process.env.REACT_APP_BILLBOARD_REVISION,
    });
    appsignal.use(consoleBC());
    appsignal.use(networkBC());
    appsignal.use(windowEvents());
    appsignal.use(pathDecorator());
    if (window.env.DATADOG_CLIENT_TOKEN &&
        window.env.DATADOG_APPLICATION_ID &&
        window.env.DATADOG_ENV) {
        datadogRum.init({
            applicationId: window.env.DATADOG_APPLICATION_ID,
            clientToken: window.env.DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'billboard',
            env: window.env.DATADOG_ENV,
            version: window.env.REACT_APP_BILLBOARD_REVISION,
            trackInteractions: true,
            trackFrustrations: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask',
            telemetrySampleRate: 0,
        });
        datadogLogs.init({
            clientToken: window.env.DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.com',
            service: 'billboard',
            env: window.env.DATADOG_ENV,
            version: window.env.REACT_APP_BILLBOARD_REVISION,
            sampleRate: 100,
            forwardErrorsToLogs: true,
        });
    }
}
export { appsignal as appSignal };
export { Span };
export var LEVELS = {
    debug: 'debug',
    info: 'info',
    warn: 'warn',
    error: 'error',
};
export var createAppSignalBackend = function (appSignal) {
    if (appSignal === void 0) { appSignal = appsignal; }
    return function () { return ({
        log: function (message, logLevel, attributes) {
            if (logLevel !== LEVELS.error) {
                return;
            }
            var span = appSignal.createSpan(function (span) {
                if (message instanceof Error) {
                    span.setError(message);
                }
                else if (typeof message === 'string') {
                    span.setError(new Error(message));
                }
                else {
                    span.setError(new Error("Unknown error thrown: ".concat(message)));
                }
                if (attributes) {
                    span.setTags(attributes);
                }
                return span;
            });
            appSignal.send(span);
        },
    }); };
};
export var createDatadogBackend = function (datadogLogger) {
    if (datadogLogger === void 0) { datadogLogger = datadogLogs.logger; }
    return function (
    // TODO: Add support for creating distinct loggers based on `name`.
    //   Currently, this parameter is unused.
    name) { return ({
        log: function (message, logLevel, attributes) {
            if (message instanceof Error && message.stack) {
                attributes = __assign(__assign({}, attributes), { 'error.stack': message.stack });
            }
            datadogLogger[logLevel]("".concat(message), attributes);
        },
    }); };
};
export var consoleBackend = function () { return ({
    log: function (message, logLevel, attributes) {
        var args = [message];
        if (attributes) {
            args.push(attributes);
        }
        // eslint-disable-next-line no-console
        console[logLevel].apply(console, args);
    },
}); };
var GenericLogger = /** @class */ (function () {
    function GenericLogger(_a) {
        var _b = _a === void 0 ? {} : _a, name = _b.name, _c = _b.middlewares, middlewares = _c === void 0 ? [] : _c, _d = _b.backends, backends = _d === void 0 ? [] : _d;
        this._middlewares = middlewares;
        this._backends = backends.map(function (backend) { return backend(name); });
    }
    GenericLogger.prototype.log = function (message, logLevel, attributes) {
        var _this = this;
        var middlewares = this._middlewares;
        function getMiddlewareGenerator() {
            var _i, middlewares_1, middleware;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _i = 0, middlewares_1 = middlewares;
                        _a.label = 1;
                    case 1:
                        if (!(_i < middlewares_1.length)) return [3 /*break*/, 4];
                        middleware = middlewares_1[_i];
                        return [4 /*yield*/, middleware];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        }
        var middlewareGenerator = getMiddlewareGenerator();
        var next = function (message, logLevel, attributes) {
            var middleware = middlewareGenerator.next();
            if (middleware.value) {
                middleware.value({ message: message, logLevel: logLevel, attributes: attributes, next: next });
            }
            if (middleware.done) {
                _this._logToBackends(message, logLevel, attributes);
            }
        };
        next(message, logLevel, attributes);
    };
    GenericLogger.prototype.debug = function (message, attributes) {
        this.log(message, LEVELS.debug, attributes);
    };
    GenericLogger.prototype.info = function (message, attributes) {
        this.log(message, LEVELS.info, attributes);
    };
    GenericLogger.prototype.warn = function (message, attributes) {
        this.log(message, LEVELS.warn, attributes);
    };
    GenericLogger.prototype.error = function (message, attributes) {
        this.log(message, LEVELS.error, attributes);
    };
    GenericLogger.prototype._logToBackends = function (message, logLevel, attributes) {
        for (var _i = 0, _a = this._backends; _i < _a.length; _i++) {
            var backend = _a[_i];
            backend.log(message, logLevel, attributes);
        }
    };
    return GenericLogger;
}());
export var createLogger = function (_a) {
    var name = _a.name, middlewares = _a.middlewares, backends = _a.backends;
    return new GenericLogger({ name: name, middlewares: middlewares, backends: backends });
};
export var reactWarningsMiddleware = function (_a) {
    var message = _a.message, logLevel = _a.logLevel, attributes = _a.attributes, next = _a.next;
    if (message instanceof Error &&
        (message.message.includes('Warning: Function components cannot be given refs.') ||
            message.message.includes('See https://reactjs.org/link/unsafe-component-lifecycles for details'))) {
        return;
    }
    next(message, logLevel, attributes);
};
export var defaultMiddlewares = [reactWarningsMiddleware];
export var defaultBackends = [
    consoleBackend,
    createAppSignalBackend(),
    createDatadogBackend(),
];
export var createDefaultLogger = function (_a) {
    var _b = _a === void 0 ? {} : _a, name = _b.name, _c = _b.middlewares, middlewares = _c === void 0 ? [] : _c, _d = _b.backends, backends = _d === void 0 ? [] : _d;
    return createLogger({
        name: name,
        middlewares: __spreadArray(__spreadArray([], defaultMiddlewares, true), middlewares, true),
        backends: __spreadArray(__spreadArray([], defaultBackends, true), backends, true),
    });
};
var defaultLogger = createDefaultLogger();
export default defaultLogger;
/**
 * This is just a thin wrapper over `logger.error` for backwards compatibility.
 * @deprecated Use `logger.error` directly instead.
 */
export var logError = function (error) {
    defaultLogger.error(error);
};
