"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.esUS = void 0;
var esUS = exports.esUS = {
  common: {
    errorMessage: 'Ha ocurrido un error',
    months: {
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre'
    },
    today: 'Hoy',
    weekDayLong: {
      Sunday: 'Domingo',
      Monday: 'Lunes',
      Tuesday: 'Martes',
      Wednesday: 'Miércoles',
      Thursday: 'Jueves',
      Friday: 'Viernes',
      Saturday: 'Sábado'
    },
    weekDayShort: {
      Sunday: 'Do',
      Monday: 'Lu',
      Tuesday: 'Ma',
      Wednesday: 'Mi',
      Thursday: 'Ju',
      Friday: 'Vi',
      Saturday: 'Sa'
    },
    dateFormats: {
      year: 'YYYY',
      yearMonth: 'YYYY-MM',
      monthDayYear: 'MM-DD-YYYY',
      yearMonthDay: 'YYYY-MM-DD'
    },
    languages: {
      English: 'Inglés',
      French: 'Francés',
      German: 'Alemán',
      Italian: 'Italiano',
      Spanish: 'Español'
    },
    clear: 'Limpiar',
    yes: 'Si',
    no: 'No',
    other: 'Otro',
    preferNotAnswer: 'Prefiero no responder',
    male: 'Masculino',
    female: 'Femenino'
  },
  components: {
    deleteButton: {
      confirmation: '¿Estás seguro de que quieres borrar esto?'
    },
    uploader: {
      removeHeading: '¿Eliminar esta Imagen?',
      removeImage: 'Eliminar Imagen',
      cancel: 'Cancelar',
      numberOfFilesError: 'No se cargaron los archivos debido al número de archivos. Por favor, sube hasta {{MaxFiles}} fotos y vuelve a intentarlo.',
      uploadRules: 'Los archivos deben estar por debajo de {{formattedBytes}} y en formato {{extensions}}. No se aceptarán archivos PDF.'
    },
    checkboxGroup: {
      noneAbove: 'Ninguno de los anteriores'
    },
    connectedFile: {
      imageError: 'Hubo un problema con su imagen, por favor inténtalo de nuevo.',
      enterUrl: 'Por favor ingresa la url de la imagen',
      processing: 'Procesando',
      bytes: 'bytes',
      clickSelect: 'Haz clic para seleccionar un archivo o deja caer un archivo aquí. Formatos de archivo aceptados:',
      clickSelectUrl: 'Haz clic para adicionar la URL de la imagen',
      deleteConfirm: '¿Estás seguro de que quieres borrar esto?',
      preview: 'Previsualizar',
      clear: 'Limpiar'
    },
    datePicker: {
      month: 'Mes',
      day: 'Día',
      year: 'Año'
    },
    fieldHeight: {
      feet: 'Ft',
      inch: 'In'
    },
    insuranceProviderId: {
      idImage: 'Tarjeta Id Humana',
      medicareId: 'Número de Identificación de Medicare Advantage',
      reenter: 'Por favor, vuelve a ingresar',
      notMember: 'Actualmente no soy miembro de Humana Medicare Advantage.'
    },
    password: {
      password: 'Crear Contraseña',
      confirm: 'Confirmar',
      validating: 'Validando',
      ok: 'Ok'
    },
    progressBar: {
      estimation: 'En promedio, esto puede tomar {{time}} para completarse.',
      minutes_one: '{{count}} minuto',
      minutes_other: '{{count}} minutos',
      percentage: 'Se ha completado el <span id="t-progressValue">{{progress}}%</span> del proceso de registro.'
    },
    rangeSlider: {
      maxMinEntryError: 'Asegúrese de que la longitud máxima de la entrada sea mayor que la longitud mínima de la entrada.'
    },
    error: {
      errorOccurred: 'Un Error ha ocurrido',
      somethingWrong: '<p>Hola, parece que algo salió mal de nuestra parte. Actualiza tu página para volver a intentarlo.</p><p>Si el problema persiste, póngase en contacto con soporte.</p>',
      defaultError: 'Algo salió mal de nuestra parte. Intente actualizar la página, si el problema persiste, póngase en contacto con el soporte.',
      contributionDoesNotExist: 'Esta contribución ya no existe',
      contributionDoesNotExistMessage: 'Parece que la contribución que seleccionaste ya no esta disponible.'
    }
  },
  fields: {
    age: {
      standardLabel: '¿Cuál es tu edad actual (en años)?'
    },
    dob: {
      yearOfBirth: '¿Cuál es tu año de nacimiento?',
      monthYearBirth: '¿Cuál es tu mes y año de nacimiento?',
      dateOfBirth: '¿Cuál es tu fecha de nacimiento?'
    },
    emailConfirm: {
      enterEmail: 'Por favor, escribe tu correo eléctronico',
      reEnterEmail: 'Por favor, repita tu correo eléctronico'
    },
    english: {
      label: '¿Usted sabe leer, hablar y entender inglés?'
    },
    ethnicity: {
      label: '¿Cuál es tu etnia?',
      hispOrLat: 'Hispano o Latino',
      notHispOrLat: 'No Hispano o Latino'
    },
    gender: {
      label: '¿Cuál es tu género?'
    },
    heightInternational: {
      enterHeight: 'Escribe tu altura',
      enterHeightCentimeters: 'Escribe tu altura en centímetros',
      imperial: 'Imperial',
      metric: 'Métrico'
    },
    weightInternational: {
      imperialWeight: 'Escribe tu peso en libras',
      stoneWeight: 'Escribe tu peso en stones',
      metricWeight: 'Escribe tu peso en kilogramos',
      pounds: 'Libras',
      stones: 'Stones',
      kilograms: 'Kilogramos'
    },
    languageIntl: {
      label: '¿Eres capaz de leer hablar y entender el {{language}}?'
    },
    fileUpload: {
      uploadingFiles: 'Subiendo archivos',
      numberFiles_one: '{{count}} Archivo',
      numberFiles_other: '{{count}} Archivos',
      formatFileNumError: 'El número de archivos seleccionados supera el máximo permitido. Se han agregado los primeros {{number}} archivos seleccionados.',
      fileSizeError: 'Confirme que cada archivo tiene menos de {{size}} y en formato JPEG o PNG.',
      selectDropFile: 'Haga clic para seleccionar el archivo o arrastrar y soltar hasta {{maxFileCount}} aquí.',
      formatAccepted: 'Formatos permitidos: {{formatAccept}}',
      uploadAFile: 'Sube al menos un archivo.'
    },
    sex: {
      label: 'Cual es tu genero?'
    },
    countryResident: {
      questionLabel: '¿Vives actualmente en {{country}}?',
      US: 'el EE.UU',
      UK: 'el Reino Unido',
      Germany: 'Alemania',
      France: 'Francia',
      Canada: 'Canada',
      Italy: 'Italia',
      Spain: 'España'
    },
    usResident: {
      questionLabel: '¿Vives actualmente en los estados unidos?'
    },
    pregnancy: {
      questionLabel: '¿Estas actualmente embarazada?'
    }
  },
  validations: {
    required: 'Este campo es requerido',
    greaterThanZero: 'Ingrese un valor mayor a 0.',
    invalidEmail: 'Su dirección de correo electrónico no parece ser válida.',
    alphaNumericError: 'Este campo solo puede contener letras y números.',
    lengthError: {
      max: 'Este campo debe de contener mas de {{max}} caracteres (longitud actual {{length}})',
      min: 'Este campo debe contener por lo menos {{min}} caracteres (longitud actual {{length}})'
    },
    textOnly: 'Este campo sólo puede contener letras',
    phoneError: 'Este campo debe tener el formato de 12345678999 o 1 (234) 567-8999',
    dateValidation: 'Seleccione un día, mes y año.',
    timeValidation: 'Utilice el formato 11:11 AM',
    zipValidation: 'El código postal de EE. UU. debe contener 5 dígitos.',
    invalidJson: 'El archivo tiene un formato JSON no válido',
    invalidFileType: '{{fileName}} es un tipo de archivo no válido. El formato de archivo aceptado es: {{acceptedTypes}}',
    invalidFileTypes: '{{fileName}} es un tipo de archivo no válido. Los formatos de archivo aceptados son: {{acceptedTypes}}',
    noEmptyArray: 'Archivos no cargados debido a la cantidad de archivos. Por favor cargue hasta {{maxFiles}} fotos y vuelva a intentarlo.',
    maxFilesUpload: 'Ha seleccionado demasiados archivos.',
    maxFileSize: 'Los archivos {{fileList}} superan el tamaño de archivo permitido.',
    humanaLengthValidation: 'Su ID de Humana debe ser una H más {{lenght}} números.',
    mustCheck: 'Esto debe verificarse para continuar.',
    bpValid: 'Ingrese una medida de presión arterial válida',
    pleaseConfirmEmail: 'Por favor, confirme que ha escrito correctamente su dirección de correo electrónico.',
    datetime: {
      selectDateTime: 'Por favor seleccione una fecha y hora',
      validDate: 'Por favor introduzca una fecha valida',
      validTime: 'Por favor introduzca una hora valida',
      validDateRange: 'Ingrese una fecha en el rango {{startDate}} - {{endDate}}',
      validAfterDate: 'Ingrese una fecha posterior a {{validDate}}',
      validBeforeDate: 'Ingrese una fecha anterior a {{validDate}}',
      validTimeRange: 'Ingrese un tiempo en el rango {{timeRange}}'
    },
    dob: {
      invalidDate: 'Fecha invalida',
      invalidDateFormat: 'Formato de fecha no válido, formato esperado {{format}}',
      cannotBeFuture: 'La fecha no puede estar en futuro.'
    },
    number: {
      minMax: 'Responda con un número entre {{min}} - {{max}}.',
      decimal: 'Responda con un número que contenga {{decimal}} decimales (Ej.: X{{ceros}})'
    },
    heightInternational: {
      metricErroMsg: 'Introduzca un valor de 91 a 243 centímetros.',
      imperialErrorMsg: 'Ingrese pies y pulgadas'
    },
    weightInternational: {},
    password: {
      casing: 'Al menos 1 carácter en mayúscula y minúscula',
      symbolAndNumber: 'Al menos 1 símbolo y número',
      length: 'Al menos 8 caracteres de longitud'
    }
  }
};