var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
/**
 * A simple, inline loading indicator component. Any props given are
 * passed down to the inline SVG element that is rendered. To control
 * the size, pass `width` and `height` and the dots will be scaled
 * automatically; to change the color, use the `fill` prop.
 */
export function LoadingIndicator(props) {
    var largeRadius = 15;
    var smallRadius = 9;
    var midRadius = (largeRadius + smallRadius) / 2;
    var spacing = 5;
    var height = largeRadius * 2;
    var width = largeRadius * 6 + spacing * 2;
    var duration = '1.25s';
    var keyTimes = '0 ; 0.15 ; 0.3 ; 0.45 ; 0.60 ; 0.75 ; 0.9 ; 1';
    var lowOpacity = 0.6;
    var highOpacity = 1;
    var midOpacity = (highOpacity + lowOpacity) / 2;
    return (React.createElement("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: width, height: height, viewBox: "0 0 ".concat(width, " ").concat(height), fill: "#00C480" }, props),
        React.createElement("circle", { cx: largeRadius, cy: largeRadius },
            React.createElement("animate", { attributeName: "r", from: largeRadius, to: largeRadius, dur: duration, keyTimes: keyTimes, values: [
                    smallRadius,
                    midRadius,
                    largeRadius,
                    midRadius,
                    smallRadius,
                    smallRadius,
                    smallRadius,
                    smallRadius,
                ].join(' ; '), repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", dur: duration, keyTimes: keyTimes, values: [
                    lowOpacity,
                    midOpacity,
                    highOpacity,
                    midOpacity,
                    lowOpacity,
                    lowOpacity,
                    lowOpacity,
                    lowOpacity,
                ].join(' ; '), repeatCount: "indefinite" })),
        React.createElement("circle", { cx: largeRadius * 3 + spacing, cy: largeRadius },
            React.createElement("animate", { attributeName: "r", dur: duration, keyTimes: keyTimes, values: [
                    smallRadius,
                    smallRadius,
                    midRadius,
                    largeRadius,
                    midRadius,
                    smallRadius,
                    smallRadius,
                    smallRadius,
                ].join(' ; '), repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", dur: duration, keyTimes: keyTimes, values: [
                    lowOpacity,
                    lowOpacity,
                    midOpacity,
                    highOpacity,
                    midOpacity,
                    lowOpacity,
                    lowOpacity,
                    lowOpacity,
                ].join(' ; '), repeatCount: "indefinite" })),
        React.createElement("circle", { cx: largeRadius * 5 + spacing * 2, cy: largeRadius },
            React.createElement("animate", { attributeName: "r", from: largeRadius, to: largeRadius, dur: duration, keyTimes: keyTimes, values: [
                    smallRadius,
                    smallRadius,
                    smallRadius,
                    midRadius,
                    largeRadius,
                    midRadius,
                    smallRadius,
                    smallRadius,
                ].join(' ; '), repeatCount: "indefinite" }),
            React.createElement("animate", { attributeName: "fill-opacity", dur: duration, keyTimes: keyTimes, values: [
                    lowOpacity,
                    lowOpacity,
                    lowOpacity,
                    midOpacity,
                    highOpacity,
                    midOpacity,
                    lowOpacity,
                    lowOpacity,
                ].join(' ; '), repeatCount: "indefinite" }))));
}
