import { palette } from './palette';
export { palette } from './palette';
export { spacing, getSpacingScale } from "./spacing";
export var color = {
    palette: palette,
    brand: {
        primary: palette.yellow[50],
        secondary: palette.blue[50],
    },
    text: {
        default: palette.gray[90],
        secondary: palette.gray[60],
        disabled: palette.gray[50],
        link: palette.blue[50],
        error: palette.red[50],
        warning: palette.orange[70],
        success: palette.green[50],
        white: palette.white,
    },
    surface: {
        default: palette.white,
        disabled: palette.gray[5],
    },
    background: {
        default: palette.cool_gray,
        white: palette.white,
    },
    icon: {
        default: palette.gray[90],
        secondary: palette.gray[60],
        disabled: palette.gray[50],
        select: palette.blue[50],
        white: palette.white,
    },
    states: {
        default: palette.blue[50],
        hover: palette.blue[40],
        hover_subdued: palette.blue[5],
        pressed: palette.blue[60],
        disabled: palette.gray[10],
        focus: palette.blue[50],
        focus_error: palette.red[40],
        focus_warning: palette.orange[40],
        focus_success: palette.green[40],
    },
    borders: {
        default: palette.gray[60],
        decorative: palette.gray[20],
        disabled: palette.gray[50],
        hover: palette.blue[50],
        focus: palette.blue[40],
        error: palette.red[50],
        warning: palette.orange[50],
        success: palette.green[50],
    },
    status: {
        error: palette.red[50],
        error_subdued: palette.red[5],
        warning: palette.orange[40],
        warning_subdued: palette.orange[5],
        success: palette.green[50],
        success_subdued: palette.green[5],
        informational: palette.blue[50],
        informational_subdued: palette.blue[5],
    },
};
