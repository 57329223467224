"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.passwordValidation = exports.passwordRules = void 0;
var _i18n = require("../../i18n");
var passwordRules = exports.passwordRules = [{
  regex: /(?=.*[A-Z])(?=.*[a-z])/,
  translationKey: 'validations.password.casing'
}, {
  regex: /(?=.*\d)(?=.*\W)/,
  translationKey: 'validations.password.symbolAndNumber'
}, {
  regex: /.{12,}/,
  translationKey: 'validations.password.length'
}];
var passwordValidation = exports.passwordValidation = function passwordValidation() {
  var v = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  for (var _i = 0, _passwordRules = passwordRules; _i < _passwordRules.length; _i++) {
    var _passwordRules$_i = _passwordRules[_i],
      translationKey = _passwordRules$_i.translationKey,
      regex = _passwordRules$_i.regex;
    if (v && !regex.test(v)) {
      return (0, _i18n.globalTranslate)(translationKey);
    }
  }
  return undefined;
};
var validations = exports.validations = function validations(props) {
  return [passwordValidation];
};