var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useCallback } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Select } from '../Select';
import { color } from '../system';
export var StructuredEntryInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  outline: none;\n  padding: 1rem;\n  border: 0;\n  &:focus {\n    border-bottom: 2px solid ", ";\n  }\n"], ["\n  width: 100%;\n  outline: none;\n  padding: 1rem;\n  border: 0;\n  &:focus {\n    border-bottom: 2px solid ", ";\n  }\n"])), color.borders.focus);
export var StructuredEntry = function (_a) {
    var _b = _a.validate, validate = _b === void 0 ? function (val) { return undefined; } : _b, transform = _a.transform, label = _a.label, structuredEntryPlaceholder = _a.structuredEntryPlaceholder, onChange = _a.onChange, rest = __rest(_a, ["validate", "transform", "label", "structuredEntryPlaceholder", "onChange"]);
    var _c = useState(), selectedOptions = _c[0], setSelectedOptions = _c[1];
    var _d = useState(undefined), structuredEntryError = _d[0], setStructuredEntryError = _d[1];
    var _e = useState(''), structuredEntryValue = _e[0], setStructuredEntryValue = _e[1];
    var handleChange = useCallback(function (values) {
        setSelectedOptions(values);
        onChange(values);
    }, [onChange]);
    var handleKeyDown = useCallback(function (evt) {
        if (evt.code === 'Space') {
            // headlessui/react overrides this for
            // focus management and will try to highjack our spacebar operation
            // IMPORTANT: you must only stop the propogation on space
            evt.stopPropagation();
        }
        if (evt.code === 'Enter') {
            var validationResult = validate(structuredEntryValue);
            if (validationResult) {
                setStructuredEntryError(validationResult);
            }
            else {
                if (transform) {
                    var values = transform(structuredEntryValue);
                    handleChange(__spreadArray(__spreadArray([], (selectedOptions !== null && selectedOptions !== void 0 ? selectedOptions : []), true), values, true));
                }
                else {
                    handleChange(__spreadArray(__spreadArray([], (selectedOptions !== null && selectedOptions !== void 0 ? selectedOptions : []), true), [
                        { label: structuredEntryValue, value: structuredEntryValue },
                    ], false));
                }
                setStructuredEntryValue('');
                setStructuredEntryError(undefined);
            }
        }
    }, [selectedOptions, structuredEntryValue, validate, transform, handleChange]);
    return (React.createElement(Select, __assign({}, rest, { multiple: true, value: selectedOptions === null || selectedOptions === void 0 ? void 0 : selectedOptions.map(function (v) { return v.value; }), options: selectedOptions, onChange: handleChange, label: label, renderOptions: function () { return (React.createElement(React.Fragment, null,
            React.createElement(StructuredEntryInput, { value: structuredEntryValue, onKeyDown: handleKeyDown, autoFocus: true, type: "text", onClick: function (evt) { return evt.stopPropagation(); }, placeholder: structuredEntryPlaceholder, onChange: function (evt) { return setStructuredEntryValue(evt.target.value); } }),
            structuredEntryError && (React.createElement("div", { className: "error", style: { paddingLeft: '.2rem', fontWeight: 600, fontSize: 12 } }, structuredEntryError)))); } })));
};
var templateObject_1;
